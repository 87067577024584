<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="30px"
          max-width="30px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

        <h2 class="text--primary">
          {{ appName }}
        </h2>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="require(`@/assets/images/misc/mask-v2-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
            />

            <!-- tree -->
            <v-img
              height="226"
              width="300"
              class="auth-tree"
              src="@/assets/images/misc/tree-2.png"
            ></v-img>

            <!-- 3d character -->
            <div class="d-flex align-center h-full pa-16 pe-0">
              <v-img
                contain
                max-width="100%"
                height="400"
                class="auth-3d-group"
                :src="require(`@/assets/images/3d-characters/illustration-register-v2-${$vuetify.theme.dark ? 'dark' : 'light'}.png`)"
              ></v-img>
            </div>
          </div>
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pa-10 pb-0"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p
                    class="text-2xl font-weight-semibold text--primary mb-2"
                  >
                    Forgot Password? 🔒
                  </p>
                  <p class="mb-2">
                    Enter your email and we'll send you instructions to reset your password
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form
                    ref="form"
                    v-model="valid"
                    :disabled="countdown!=0"
                    @submit.prevent="onSubmit"
                  >
                    <v-text-field
                      v-model="formData.email"
                      outlined
                      dense
                      label="Email"
                      hide-details="auto"
                      class="mb-6"
                      :rules="[validators.required, validators.emailValidator]"
                      :error-messages="errors.email"
                      @change="errors.email = []"
                    ></v-text-field>

                    <v-card-text
                      :style="{'color': messageColor}"
                      class="d-flex align-center justify-center mt-4"
                    >
                      {{ message }}
                    </v-card-text>
                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      :disabled="countdown!=0"
                    >
                      Send reset link <span v-show="countdown!=0">({{ countdown }})</span>
                    </v-btn>
                  </v-form>
                </v-card-text>

                <!-- create new account  -->
                <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
                  <router-link :to="{name:'auth.login'}">
                    &lt; back to login
                  </router-link>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import themeConfig from '@themeConfig'
import { required, emailValidator } from '@core/utils/validation'

export default {
	data() {
		return {
			countdown: 0,
			formData: { email: '' },
			valid: false,
			errors: {},
			message: '',
			messageColor: 'grey',
			appName: themeConfig.app.name,
			appLogo: themeConfig.app.logo,
			icons: {
				mdiEyeOutline,
				mdiEyeOffOutline,
			},
			isPasswordVisible: false,
			validators: { required, emailValidator },
		}
	},
	methods: {
		onSubmit() {
			if (this.valid) {
				this.countdown = 60
				this.message = ''
				this.$store
					.dispatch('auth/forgotPassword', this.formData)
					.then(response => {
						const { message } = response.data
						this.message = message
						this.messageColor = 'grey'
						const counter = setInterval(() => {
							if (this.countdown > 0) {
								this.countdown -= 1
							}
						}, 1000)
						setTimeout(() => {
							this.countdown = 0
							clearInterval(counter)
						}, 60999)
					})
					.catch(error => {
						this.countdown = 0
						const { errors, message } = error.response.data
						if (errors) {
							this.$set(this, 'errors', errors)
						}
						this.$set(this, 'valid', false)
						this.message = message
						this.messageColor = 'red'
					})
			} else {
				this.$refs.form.validate()
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '@core/preset/preset/pages/auth.scss';
</style>
